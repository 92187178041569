import { parseCookies, setCookie } from 'nookies'
import { sputnikGet } from '@onme/web-core/sputnik/client'
import { ISODateString, Promo } from 'shared/types/databaseTypes'

const promoRefreshSeconds = 60 * 60 // refresh the promo cache once an hour max
const PROMO_COOKIE_NAME = 'prmocache'

type PromoCache = {
  refreshTimestamp: ISODateString
  promos: Promo[]
}

export async function getPromos(): Promise<Promo[]> {
  const promoCookieString = parseCookies()[PROMO_COOKIE_NAME]
  if (promoCookieString) {
    const promoCookie = JSON.parse(promoCookieString)
    if ('promos' in promoCookie) {
      return Promise.resolve(promoCookie.promos)
    }
  }
  const { promos } = await sputnikGet<{ promos: Promo[] }>(
    '/v1/public/promos',
  ).catch((error) => ({
    promos: [] as Promo[],
  }))

  writeToPromoCache(promos)

  return promos
}

function writeToPromoCache(promos: Promo[]) {
  setCookie(
    null,
    PROMO_COOKIE_NAME,
    JSON.stringify({
      refreshTimestamp: new Date().toISOString(),
      promos,
    }),
    { maxAge: promoRefreshSeconds },
  )
}
