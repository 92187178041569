'use client'
import * as React from 'react'
import Button from 'components/common/input/Button'
import Link from 'next/link'
import Hamburger from './Hamburger'
import Container from '../Container'
import { twMerge } from 'tailwind-merge'
import LogoWithText from '../../logo/LogoWithText'
import { useUser } from 'lib/auth/UserContext'
import UserIconMenu from './UserIconMenu'
import SearchIcon from '../icons/Search'
import PromotionalBanner from '../PromotionalBanner'
import { useEffect, useState } from 'react'
import { Promo, PromoBannerProps } from 'shared/types/databaseTypes'
import { sputnikGet } from '@onme/web-core/sputnik/client'
import { getPromos } from 'lib/client/promoUtils'

interface HeaderBarProps {
  className?: string
  sticky?: boolean
  searchOverride?: () => void
  onPromoBannerLoad?: (promoBannerExists: boolean) => void
  logoText?: string
}

export default function HeaderBar({
  className,
  sticky = false,
  searchOverride,
  onPromoBannerLoad,
  logoText,
}: HeaderBarProps) {
  const { user } = useUser()
  const [promoBanner, setPromoBanner] = useState<PromoBannerProps | undefined>(
    undefined,
  )
  const [loadedPromos, setLoadedPromos] = useState(false)
  useEffect(() => {
    if (!loadedPromos) {
      setLoadedPromos(true)
      getPromos().then((promos) => {
        const applicablePromos = promos.filter((p) => p.banner)
        if (applicablePromos.length > 0) {
          setPromoBanner(applicablePromos[0].banner)
          if (onPromoBannerLoad) onPromoBannerLoad(true)
        } else {
          if (onPromoBannerLoad) onPromoBannerLoad(false)
        }
      })
    }
  })

  return (
    <div
      className={twMerge(
        `z-30 mb-1 bg-white md:mb-6 ${sticky && 'sticky top-0 z-30 w-full shadow'} ${className}`,
      )}
    >
      {promoBanner && <PromotionalBanner {...promoBanner} />}
      <Container
        outerClassName="pb-[6px] max-md:pt-2 pt-[8px]"
        className={`flex flex-row items-center justify-between`}
      >
        <div className="flex flex-row items-center justify-start gap-8 text-center">
          <Link href="/" className="mr-8 no-underline">
            <LogoWithText color={'GLOW'} text={logoText} />
          </Link>
          <Link href="/blog" className="no-underline max-md:hidden">
            Blog
          </Link>
          <Link href="/faq" className="no-underline max-md:hidden">
            FAQ
          </Link>
        </div>

        <div className="flex flex-row items-center justify-end gap-4 max-md:hidden">
          {searchOverride ? (
            <Button secondary onClick={searchOverride}>
              Search gift cards
            </Button>
          ) : (
            <Button
              primary
              href="/shop-gift-cards"
              analytics={{
                name: 'view_item_list',
                metadata: {
                  location_id: 'header_bar',
                },
              }}
            >
              View all gift cards
            </Button>
          )}
          {user ? (
            (() => {
              const firstname = user.names.givenName
              const lastname = user.names.familyName
              return (
                <>
                  <span className="font-normal">{`Hi ${firstname}`}</span>
                  <UserIconMenu name={{ firstname, lastname }} />
                </>
              )
            })()
          ) : (
            <>
              <Button secondary href="/login">
                Login
              </Button>
            </>
          )}
        </div>
        <div className="flex flex-row gap-2 md:hidden">
          {searchOverride ? (
            <SearchIcon
              className="size-6 fill-[#323232] transition-colors hover:fill-black"
              onClick={searchOverride}
            />
          ) : (
            <Link
              href={'/shop-gift-cards?intent=search'}
              aria-label="Search for a gift card"
            >
              <SearchIcon className="size-6 fill-[#323232] transition-colors hover:fill-black" />
            </Link>
          )}

          <Hamburger />
        </div>
      </Container>
    </div>
  )
}
