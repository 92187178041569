import { sputnikPost, sputnikPut } from './client'
import type {
  LegacyGiftDraft,
  GiftDraftID,
  LegacyGiftDraftInput,
} from 'shared/types/giftTypes'

// Create a new giftDraft and return the giftDraft id
export function createGiftDraft(
  giftDraftInput: LegacyGiftDraftInput,
): Promise<{ giftDraft: LegacyGiftDraft }> {
  return sputnikPost('/v1/public/gift-drafts', { giftDraftInput })
}

// Create or update a giftDraft and return the giftDraft id
export function updateGiftDraft(
  giftDraftID: GiftDraftID,
  giftDraftInput: Partial<LegacyGiftDraftInput>,
): Promise<LegacyGiftDraft> {
  return sputnikPut('/v1/public/gift-drafts/' + giftDraftID, { giftDraftInput })
}

export function sputnikBeacon(url: string, body: any) {
  if (navigator.sendBeacon) {
    return navigator.sendBeacon(
      process.env.NEXT_PUBLIC_SPUTNIK_API_URL + url,
      JSON.stringify(body),
    )
  } else {
    return fetch(process.env.NEXT_PUBLIC_SPUTNIK_API_URL + url, {
      body: JSON.stringify(body),
      method: 'POST',
      keepalive: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
  }
}

// make a GET request
export function get(url: string) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    throw response
  })
}

// make a POST request
export function post(url: string, body: any) {
  return fetch(url, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    throw response
  })
}

// make a PUT request
export function put(url: string, body: any) {
  return fetch(url, {
    body: JSON.stringify(body),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => response.json())
}

// make a POST request without stringifying the body.
export function postRaw(url: string, body: any) {
  return fetch(url, {
    body,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  }).then((response) => response.json())
}

// make a DELETE request
export function del(url: string, body: any) {
  return fetch(url, {
    body: JSON.stringify(body),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => response.json())
}
