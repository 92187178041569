import APIError from 'shared/sputnik/APIError'
import { SputnikResponse } from 'shared/sputnik/types'
import { getAuth } from 'firebase/auth'

export const sputnikGet = async <ResponseData>(
  path: string,
  params?: { [key: string]: string },
): Promise<ResponseData> => {
  return await sputnikFetch<ResponseData>(path, 'GET', { params })
}

export const sputnikPost = async <ResponseData>(
  path: string,
  body: any,
): Promise<ResponseData> => {
  return await sputnikFetch<ResponseData>(path, 'POST', { body })
}

export const sputnikPut = async <ResponseData>(
  path: string,
  body: any,
): Promise<ResponseData> => {
  return await sputnikFetch<ResponseData>(path, 'PUT', { body })
}

export const sputnikFetch = async <ResponseData>(
  path: string,
  method: string,
  { params, body }: { params?: { [key: string]: string }; body?: any },
): Promise<ResponseData> => {
  const url = new URL(path, process.env.NEXT_PUBLIC_SPUTNIK_API_URL)
  for (const key in params) {
    url.searchParams.append(key, params[key])
  }

  const auth = getAuth()
  let authHeader = {}
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken()
    authHeader = {
      Authorization: `Bearer ${token}`,
    }
  }

  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      ...authHeader,
    },
    body: JSON.stringify(body),
  })

  try {
    const responseBody: SputnikResponse<ResponseData> = await response.json()
    if (responseBody.success) {
      return responseBody.data as ResponseData
    } else if (responseBody.error) {
      throw new APIError(responseBody.error)
    } else {
      throw new APIError('Malformed response')
    }
  } catch (error) {
    if (error instanceof APIError) {
      console.debug('APIError:', error)
    } else {
      console.debug('Error:', error)
    }
    throw error
  }
}
